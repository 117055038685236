import React from 'react'
import Helmet from 'react-helmet';
import { Link, graphql } from 'gatsby';
import ContactBar from '../components/ContactBar';
import SingleImage from '../library/SingleImage';
import BookNow from '../components/BookNow';
import Contact from '../components/Contact';
import Footer from '../components/Footer';
import Layout from '../components/Layout';


const AngebotPage = (props) => (
  <Layout location={props.location}>
    <div id="angebot" className="page">
      <Helmet>
        <title>Angebot, Behandlungsmethoden</title>
        <meta name="description" content="TCM, Akupunktur, Kräutermedizin, Moxa, Schröpfen, Mein Angebot, Behandlungsmethoden" />
        <meta property="og:title" content="Angebot, Behandlungsmethoden" />
        <meta property="og:description" content="Akupunktur, Kräutermedizin, Moxa, Schröpfen, Mein Angebot, Behandlungsmethoden" />
        {/* <meta name="keywords" content="KEYWORDs§" /> */}
      </Helmet>
      <ContactBar className="large-screen" />
      <div className="container">
        <h1>Mein Angebot</h1>
        <p>Zu meinem Angebot der Traditionell Chinesischen Medizin gehören folgende Behandlungsmethoden:</p>
        <ul className="offerList">
          <li>
            <Link to="/angebot/akupunktur/"><SingleImage title="Akupunktur" image={props.data.akupunktur.fluid}/></Link>
            {/*<div className="indications">geeignet für Schmerzzustände, Verdauungssbeschwerden, Schlafstörungen, gynäkologische Beschwerden, Schwangerschaft, Hautprobleme...</div>*/}
          </li>
          <li>
            <Link to="/angebot/kraeutermedizin/"><SingleImage title="Kräutermedizin" image={props.data.herbs.fluid}/></Link>
            {/*<div className="indications">Diese Methode wird in Kombination mit anderen Behandlungsstrategien angewendet. Ihr Therapieplan wird individuell zusammengestellt.</div>*/}
          </li>
          <li>
            <Link to="/angebot/moxa/"><SingleImage title="Moxa" image={props.data.moxa.fluid}/></Link>
            {/*<div className="indications">Diese Methode wird in Kombination mit anderen Behandlungsstrategien angewendet. Ihr Therapieplan wird individuell zusammengestellt.</div>*/}
          </li>
          <li>
            <Link to="/angebot/schroepfen/"><SingleImage title="Schröpfen" image={props.data.schroepfen.fluid}/></Link>
            {/*<div className="indications">Diese Methode wird in Kombination mit anderen Behandlungsstrategien angewendet. Ihr Therapieplan wird individuell zusammengestellt.</div>*/}
          </li>
        </ul>
      </div>
      <BookNow />
      <Contact />
      <Footer />
    </div>
  </Layout>
)

export default AngebotPage;

export const query = graphql`
  query AngebotImagesQuery {
    akupunktur: imageSharp(fluid: { originalName: {regex: "/^akupunktur/" }}) {
      fluid(maxHeight: 600, cropFocus: ENTROPY ) {
        ...GatsbyImageSharpFluid
      }
    },
    herbs: imageSharp(fluid: { originalName: {regex: "/kraeuter/" }}) {
      fluid(maxHeight: 600, cropFocus: ENTROPY ) {
        ...GatsbyImageSharpFluid
      }
    },
    moxa: imageSharp(fluid: { originalName: {regex: "/moxa/" }}) {
      fluid(maxHeight: 600, cropFocus: ENTROPY ) {
        ...GatsbyImageSharpFluid
      }
    },
    schroepfen: imageSharp(fluid: { originalName: {regex: "/schroepfen/" }}) {
      fluid(maxHeight: 600, cropFocus: ENTROPY ) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;
